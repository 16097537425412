import React from "react"

import { css } from '@emotion/react'

const ThreeColumns = ({children}) => {

    return (
        <div css={main}>
            {children}
        </div>
    )
}

export default ThreeColumns

const main = css`
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px 15px;

    @media screen and (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px 15px;
    }

    @media screen and (min-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px 15px;
    }
`
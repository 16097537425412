import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/container"

// import { css } from '@emotion/react'
import PersonCard from "../components/personCard"
import ThreeColumns from "../components/layouts/threeColumns"
// import FourColumns from "../components/layouts/fourColumns"

// Bankabbble / Admirabbble

const MakersPage = (props) => {
  const people = props.data.people.edges.map( edge => edge.node ).filter( node => node.data.Picture )
  // console.log(people)

  return (
    <Layout>
      <Seo title="Change Makers" />
  
      <Container>
        <div style={{margin: "50px 0"}}>
          {/* <h2>{people.length} change-makers inspirant.e.s</h2> */}
          <h2>Change-makers inspirant.e.s</h2>
          <p>Cette page revient très bientôt 😋</p>
          <ThreeColumns>
            {people.map( person => (
              <PersonCard 
                key={person.id}
                name={person.data.Name}
                // short_bio={person.data.ShortBio}
                picture={person.data.Picture && person.data.Picture[0].url}
                projects={person.data.Projects && person.data.Projects.map( project => project.data.Name )}
                educations={person.data.Educations && person.data.Educations.map( edu => edu.data.Name )}
              />
            ))}
          </ThreeColumns>
        </div>
      </Container>
    </Layout>
  )
}

export default MakersPage

export const makersQuery = graphql`
  query MakersQuery {
    people: allAirtable(filter: {table: {eq: "People"}}) {
      edges {
        node {
          ...PeopleCardFragment
        }
      }
    }
  }
`